










































































































































































import Vue from "vue";
import { Prop } from "vue/types/options";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

import { Business, Discount, Role } from "@/types";

import discountStoreModule from "@/store/modules/discount";
import productStoreModule from "@/store/modules/productInventory";
import serviceStoreModule from "@/store/modules/service";
import servicePackageModule from "@/store/modules/servicePackage";
import membershipStoreModule from "@/store/modules/membership";

const { mapActions: discountActions } =
  createNamespacedHelpers("DISCOUNT_FORM");

const { mapGetters: productGetters, mapActions: productActions } =
  createNamespacedHelpers("PRODUCT_");
const { mapActions: serviceActions, mapGetters: serviceGetters } =
  createNamespacedHelpers("SERVICE_");
const { mapActions: servicePackageActions, mapGetters: servicePackageGetters } =
  createNamespacedHelpers("SERVICE_PACKAGE_");
const { mapActions: membershipActions, mapGetters: membershipGetters } =
  createNamespacedHelpers("MEMBERSHIP_");

export default Vue.extend<any, any, any, any>({
  name: "DiscountForm",
  props: {
    discount: {
      type: Object as Prop<Discount>,
    },
  },
  data: () => ({
    valid: false,
    title: "",
    expiresOn: "",
    startedOn: "",
    status: "",
    productsApplied: [],
    servicesApplied: [],
    servicePackagesApplied: [],
    membershipsApplied: [],
    applyTo: "all",
    value: null,
    valueType: "percentage",
    allProducts: false,
    allServices: false,
    allPackages: false,
    allMemberships: false,
    platform: "",
    applyOn: "original-amount",
  }),
  computed: {
    ...servicePackageGetters(["servicePackagePage"]),
    ...productGetters(["productPage"]),
    ...serviceGetters(["servicePage"]),
    ...membershipGetters(["membershipPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
    getRules: () => (field: string) =>
      [(v: string) => !!v || `${field} field is required`],
  },
  created() {
    this.resetForm();
    this.loadData();
  },
  methods: {
    ...discountActions(["createDiscount", "updateDiscount"]),
    ...productActions(["fetchProductList"]),
    ...serviceActions(["fetchServiceList"]),
    ...servicePackageActions(["fetchServicePackageList"]),
    ...membershipActions(["fetchMembershipList"]),
    validateForm() {
      (
        this.$refs.discountForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!this.valid) return;

      if (this.discount && this.discount._id) {
        this.updateDiscount({
          id: this.discount._id,
          discount: {
            title: this.title,
            expiresOn: this.expiresOn,
            startedOn: this.startedOn,
            status: this.status,
            productsApplied: this.productsApplied,
            servicesApplied: this.servicesApplied,
            servicePackagesApplied: this.servicePackagesApplied,
            membershipsApplied: this.membershipsApplied,
            //applyTo: this.applyTo,
            value: this.value,
            valueType: this.valueType,
            allProducts: this.allProducts,
            allServices: this.allServices,
            allPackages: this.allPackages,
            allMemberships: this.allMemberships,
            platform: this.platform,
            applyOn: this.applyOn,
          },
        }).then((discount) => {
          if (discount) {
            this.$emit("data-saved", discount);
          }
        });
      } else {
        const businessId = (this.role.business as Business)._id;
        this.createDiscount({
          title: this.title,
          expiresOn: this.expiresOn,
          startedOn: this.startedOn,
          businessId,
          productsApplied: this.productsApplied,
          servicesApplied: this.servicesApplied,
          servicePackagesApplied: this.servicePackagesApplied,
          membershipsApplied: this.membershipsApplied,
          allProducts: this.allProducts,
          allServices: this.allServices,
          allPackages: this.allPackages,
          allMemberships: this.allMemberships,
          // applyTo: this.applyTo,
          value: this.value,
          valueType: this.valueType,
          applyOn: this.applyOn,
          platform: this.platform,
        }).then((discount) => {
          if (discount) {
            this.$emit("data-saved", discount);
          }
        });
      }
    },
    resetForm() {
      let expiresOn = "";
      let startedOn = "";
      if (this.discount) {
        expiresOn = moment(this.discount.expiresOn).tz(tz).format("YYYY-MM-DD");
        startedOn = moment(this.discount.startedOn).tz(tz).format("YYYY-MM-DD");
      }
      this.title = this.discount?.title || "";
      this.expiresOn = expiresOn || "";
      this.startedOn = startedOn || "";
      this.status = this.discount?.status || "";
      this.value = this.discount?.value ?? 0;

      this.productsApplied =
        this.discount?.productsApplied.map((item) => item._id) ?? [];
      this.servicesApplied =
        this.discount?.servicesApplied.map((item) => item._id) ?? [];
      this.servicePackagesApplied =
        this.discount?.servicePackagesApplied.map((item) => item._id) ?? [];
      // this.applyTo = this.discount?.applyTo ?? "all";
      this.membershipsApplied =
        this.discount?.membershipsApplied.map((item) => item._id) ?? [];
      this.valueType = this.discount?.valueType ?? "percentage";

      this.allProducts = this.discount.allProducts;
      this.allServices = this.discount.allServices;
      this.allPackages = this.discount.allPackages;
      this.allMemberships = this.discount.allMemberships;
      this.platform = this.discount.platform ?? "";
      this.applyOn = this.discount.applyOn ?? "original-amount";

      (
        this.$refs.discountForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    loadData() {
      if (this.role) {
        const bid = (this.role.business as Business)?._id;
        const params = `?businessId=${bid}&limit=10000`;
        this.fetchProductList(params);
        this.fetchServiceList(params);
        this.fetchServicePackageList(params);
        this.fetchMembershipList(params);
      }
    },
    discountRule() {
      return [(v: number) => v > 0 || `Discount value must be greater than 0`];
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("DISCOUNT_FORM")) {
      this.$store.registerModule("DISCOUNT_FORM", discountStoreModule);
    }

    if (!this.$store.hasModule("PRODUCT_")) {
      this.$store.registerModule("PRODUCT_", productStoreModule);
    }

    if (!this.$store.hasModule("SERVICE_")) {
      this.$store.registerModule("SERVICE_", serviceStoreModule);
    }

    if (!this.$store.hasModule("SERVICE_PACKAGE_")) {
      this.$store.registerModule("SERVICE_PACKAGE_", servicePackageModule);
    }

    if (!this.$store.hasModule("MEMBERSHIP_")) {
      this.$store.registerModule("MEMBERSHIP_", membershipStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("DISCOUNT_FORM");
    this.$store.unregisterModule("PRODUCT_");
    this.$store.unregisterModule("SERVICE_");
    this.$store.unregisterModule("SERVICE_PACKAGE_");
    this.$store.unregisterModule("MEMBERSHIP_");
  },
});
